import * as styles from '../login/index.module.scss';

import React, { useState } from "react";
import { isLoggedIn, setUserToken } from '../../services/auth';

import AuthenticationForm from '../../components/authentication/form';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import gql from 'graphql-tag';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';

const REGISTER_USER_MUTATION = gql`
  mutation RegisterUserMutation($lastname: String!, $firstname: String!, $email: String!, $medium: String!, $username: String!, $password: String!, $acknowledgedPrivacy: Boolean!){
    registerUser(input: { lastname: $lastname, firstname: $firstname, email: $email, medium: $medium, username: $username, password: $password, acknowledgedPrivacy: $acknowledgedPrivacy }) {
      success
      user {
        authenticationToken
      }
    }
  }
`;

const Register = ({ data }) => {
  if (isLoggedIn()) {
    navigate('/presse');
  }

  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [medium, setMedium] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [acknowledgedPrivacy, setAcknowledgedPrivacy] = useState(false);

  const [registerUser, { data: response, loading, error }] = useMutation(REGISTER_USER_MUTATION,
    {
      variables: { lastname, firstname, email, medium, username, password, acknowledgedPrivacy }
    }
  )

  const submitForm = (e) => {
    e.preventDefault();

    registerUser();
  }

  if (response && response.registerUser && response.registerUser.success ) {
    setUserToken(response.registerUser.user.authenticationToken);
    navigate('/presse');
  }

  return (
    <>
      <Seo title="Registrierung Pressebereich"/>
      <Layout>
        <AuthenticationForm title='Login Pressebereich'>
          <form onSubmit={submitForm} className={styles.form} data-loading={loading}>
            <div className={styles.errors}>
              {( error && error.message.replace('GraphQL error: ', '') )}
            </div>
            <div className={styles.fields}>
              <input className={styles.field} type="text" placeholder="Name" value={lastname} onChange={(e) => setLastname(e.target.value)}/>
              <input className={styles.field} type="text" placeholder="Vorname" value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
              <input className={styles.field} type="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
              <input className={styles.field} type="text" placeholder="Medium" value={medium} onChange={(e) => setMedium(e.target.value)}/>
              <br/><br/>
              <input className={styles.field} type="text" placeholder="Benutzername" value={username} onChange={(e) => setUsername(e.target.value)}/>
              <input className={styles.field} type="password" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)}/>
              <div className={styles.checkbox}>
                <input id="acknowledgedPrivacy" type="checkbox" onChange={(e) => setAcknowledgedPrivacy(e.target.checked)}/>
                <label htmlFor="acknowledgedPrivacy">Hiermit bestätige ich, dass ich mit den  Datenschutzbestimmungen der GEMA einverstanden bin.</label>
              </div>
              <button className={styles.button} type="submit">Jetzt registrieren</button>
            </div>
          </form>
        </AuthenticationForm>
      </Layout>
    </>
  )
}

export default Register;
